import gql from "graphql-tag";
import { EX } from "pdfmake/build/pdfmake";

// Transponder Agencys

export const ALL_TRANSPONDER_AGENCIES_QUERY = gql`
  query GetTransponderAgencies {
    getTransponderAgencies {
      abbreviation
      agency_name
      id
      prefix
      transponder_length
    }
  }
`;
export const DELETE_TRANSPONDER = gql`
  mutation DeleteTransponder($deleteTransponderId: ID!) {
    deleteTransponder(id: $deleteTransponderId) {
      id
    }
  }
`;
export const RESETPASSWORD_MUTATION = gql`
  mutation ResetPassword(
    $code: String!
    $userId: String!
    $confirmPassword: String!
    $password: String!
  ) {
    resetPassword(
      code: $code
      userId: $userId
      confirmPassword: $confirmPassword
      password: $password
    ) {
      email
      userId
      username
    }
  }
`;
export const DELETE_STATUS_MUTATION = gql`
  mutation Mutation($deleteTransponderPreOrderId: ID!) {
    deleteTransponderPreOrder(id: $deleteTransponderPreOrderId) {
      id
    }
  }
`;
export const UPDATE_TRANSPONDER = gql`
  mutation UpdateTransponder(
    $updateTransponderId: ID!
    $input: TransponderUpdateInput!
  ) {
    updateTransponder(id: $updateTransponderId, input: $input) {
      id
    }
  }
`;
export const UPDATE_TRANSPONDER_ID_MUTATION = gql`
  mutation UpdateTransponderId(
    $updateTransponderIdId: ID!
    $transponderId: String!
  ) {
    updateTransponderId(
      id: $updateTransponderIdId
      transponder_id: $transponderId
    ) {
      transponder_no
    }
  }
`;
export const UPDATE_TRANSPONDER_THRESHOLD_MUTATION = gql`
  mutation UpdateTransponderThreshold(
    $updateTransponderThresholdId: ID!
    $input: TransponderThresholdUpdateInput!
  ) {
    updateTransponderThreshold(
      id: $updateTransponderThresholdId
      input: $input
    ) {
      id
    }
  }
`;

export const TRANSPONDER_CLIENT_LOCATION_QUERY = gql`
  query GetTransponderClientLocations(
    $offset: Int!
    $limit: Int!
    $filter: TransponderClientLocationFilter
  ) {
    getTransponderClientLocations(
      offset: $offset
      limit: $limit
      filter: $filter
    ) {
      color
      location_name
      id
      client {
        id
        organization
      }
    }
  }
`;
export const ALL_TRANSPONDER_DASHBOARD_QUERY = gql`
  query GetTransponderDashBoardInsights {
    getTransponderDashBoardInsights {
      activeTransponders
      deactivatedTransponders
      newFulfillMent
      inProgressFulfillment
      completedFulfillment
    }
  }
`;
export const SINGLE_TRANSPONDER_CLIENT_LOCATION_QUERY = gql`
  query GetTransponderClientLocation($getTransponderClientLocationId: ID!) {
    getTransponderClientLocation(id: $getTransponderClientLocationId) {
      id
      color
      location_name
      client {
        id
        organization
      }
    }
  }
`;

// Transponder agency
export const TRANSPONDER_AGENCY = gql`
  query GetTransponderAgency($getTransponderAgencyId: ID!) {
    getTransponderAgency(id: $getTransponderAgencyId) {
      id
      agency_name
      abbreviation
      site_url
      prefix
      transponder_length
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_TRANSPONDER_AGENCY_MUTATION = gql`
  mutation UpdateTransponderAgency(
    $updateTransponderAgencyId: ID!
    $input: TransponderAgencyUpdateInput!
  ) {
    updateTransponderAgency(id: $updateTransponderAgencyId, input: $input) {
      id
      agency_name
      abbreviation
      site_url
      prefix
      transponder_length
      createdAt
      updatedAt
    }
  }
`;
export const ALL_TRANSPONDER_AGENCY_QUERY = gql`
  query GetTransponderAgency($getTransponderAgencyId: ID!) {
    getTransponderAgency(id: $getTransponderAgencyId) {
      abbreviation
      agency_name
      id
      prefix
      transponder_length
    }
  }
`;

export const ALL_TRANSPONDER_INSIGHTS = gql`
  query GetTransponderAccountInsights {
    getTransponderAccountInsights {
      totalTransponders
      client {
        id
        org_email
        organization
        logo
        client_type
        shadow_clients
        createdAt
        updatedAt
      }
      agencies {
        count
        agency {
          id
          agency_name
          abbreviation
          site_url
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const CREATE_TRANSPONDER_CLIENT_MUTATION = gql`
  mutation CreateTransponderClient($input: TransponderClientInput!) {
    createTransponderClient(input: $input) {
      id
    }
  }
`;

export const CREATE_TRANSPONDER_SUBCLIENT_MUTATION = gql`
  mutation CreateTransponderSubClient($input: SubClientInput!) {
    createTransponderSubClient(input: $input) {
      id
    }
  }
`;

export const CREATE_TRANSPONDER_AGENCIES_MUTATION = gql`
  mutation CreateTransponderAgency($input: TransponderAgencyInput!) {
    createTransponderAgency(input: $input) {
      id
    }
  }
`;

export const CREATE_TRANSPONDER_ACCOUNT_MUTATION = gql`
  mutation CreateTransponderAccount($input: TransponderAccountInput!) {
    createTransponderAccount(input: $input) {
      id
    }
  }
`;
//get sub clients
export const GET_SUB_CLIENTS = gql`
  query GetSubClients($filter: SubClientFilter) {
    getSubClients(filter: $filter) {
      id
      client {
        id
        organization
      }
      organization
      org_email
      logo
      createdAt
      updatedAt
    }
  }
`;
export const GET_TRANSPONDER_THRESHOLDS_QUERY = gql`
  query GetTransponderThresholds(
    $offset: Int!
    $limit: Int!
    $filter: TransponderThresholdFilter
  ) {
    getTransponderThresholds(offset: $offset, limit: $limit, filter: $filter) {
      count
      data {
        agency {
          agency_name
          abbreviation
          id
        }
        client {
          id
          organization
        }
        count
        id
      }
    }
  }
`;
// delete transpponder
export const DELETE_TRANSPONDER_MUTATION = gql`
  mutation Mutation($deleteTransponderId: ID!) {
    deleteTransponder(id: $deleteTransponderId) {
      id
    }
  }
`;
//verify preorder
export const VERIFY_PREORDER = gql`
  mutation VerifyTransponderPreOrder($verifyTransponderPreOrderId: ID!) {
    verifyTransponderPreOrder(id: $verifyTransponderPreOrderId) {
      id
    }
  }
`;
//add transponder
export const ADD_TRANSPONDER = gql`
  mutation CreateTransponder($input: TransponderInput!) {
    createTransponder(input: $input) {
      id
    }
  }
`;

// Transponder Accounts
export const ALL_TRANSPONDER_ACCOUNTS_QUERY = gql`
  query GetTransponderAccounts($filter: TransponderAccountFilter) {
    getTransponderAccounts(filter: $filter) {
      account_name
      account_no
      agency {
        abbreviation
        agency_name
        id
      }
      client {
        id
        org_email
        organization
      }
      sub_client {
        id
        organization
      }
      id
    }
  }
`;
//create transponder pre-orders
export const CREATE_TRANSPONDER_PRE_ORDER = gql`
  mutation CreateTransponderPreOrder($input: PreOrderInput!) {
    createTransponderPreOrder(input: $input)
  }
`;

//delete transponder pre-orde
export const DELETE_TRANSPONDER_PRE_ORDER = gql`
  mutation DeleteTransponderPreOrder($deleteTransponderPreOrderId: ID!) {
    deleteTransponderPreOrder(id: $deleteTransponderPreOrderId) {
      id
    }
  }
`;

export const GET_TRANSPONDER_TYPE_QUERY = gql`
  query GetTransponderTypes {
    getTransponderTypes {
      id
      transponder_type
    }
  }
`;

export const CREATE_TRANSPONDER_TYPE_MUTATION = gql`
  mutation CreateTransponderType($input: TransponderTypeInput!) {
    createTransponderType(input: $input) {
      id
    }
  }
`;

//get one transponder pre-order
export const GET_UNIQUE_TRANSPONDER_PRE_ORDER = gql`
  query GetTransponderPreOrder($getTransponderPreOrderId: ID!) {
    getTransponderPreOrder(id: $getTransponderPreOrderId) {
      id
      client {
        id
        organization
        org_email
        shadow_clients
        client_type
      }
      agency {
        abbreviation
        agency_name
        id
      }
      account {
        account_name
        account_no
        id
      }
      quantity
      batch_no
      first_sequence
      last_sequence
      date_received
      createdAt
      updatedAt
    }
  }
`;
// Get transponder
export const GET_UNIQUE_TRANSPONDER_QUERY = gql`
  query GetTransponder($getTransponderId: ID!) {
    getTransponder(id: $getTransponderId) {
      id
      sub_client {
        organization
      }
      account {
        account_name
      }
      transponder_no
      transponder_id
      transponder_status
      preorder {
        first_sequence
        last_sequence
      }
      request_status
      rma
      comment
      deactivation_date
      verified
      createdAt
      updatedAt
    }
  }
`;
// Get all transponders
export const GET_ALL_TRANSPONDERS_QUERY = gql`
  query GetTransponders($searchParams: String, $offset: Int!$limit: Int!$filter: TransponderFilter) {
    getTransponders(searchParams: $searchParams,offset: $offset, limit: $limit, filter: $filter) {
      data {
        id
        sub_client {
          organization
        }
        account {
          id
          client {
            id
            org_email
            organization
          }
          agency {
            abbreviation
            agency_name
            id
          }
          account_name
          account_no
          account_type {
            account_type
            id
          }
        }
        location {
          location_name
          id
        }
        transponder_no
        transponder_id
        transponder_status {
          id
          transponder_status
        }
        preorder {
          id
          first_sequence
          last_sequence
        }
        request_status
        rma
        comment
        deactivation_date
        verified
        createdAt
        updatedAt
      }
      count
    }
  }
`;

// TRANSPONDER ACCOUNT
export const GET_UNIQUE_TRANSPONDER_ACCOUNT_QUERY = gql`
  query GetTransponderAccount($getTransponderAccountId: ID!) {
    getTransponderAccount(id: $getTransponderAccountId) {
      account_name
      account_no
      agency {
        abbreviation
        agency_name
        createdAt
        id
        site_url
        updatedAt
      }
      client {
        client_type
        createdAt
        id
        logo
        org_email
        organization
        shadow_clients
        updatedAt
      }
      id
    }
  }
`;
// TRANSPONDER STATUSES
export const ALL_TRANSPONDER_STATUSES_QUERY = gql`
  query GetTransponderStatuses {
    getTransponderStatuses {
      id
      transponder_status
    }
  }
`;

export const CREATE_TRANSPONDER_THRESHOLD_MUTATION = gql`
  mutation CreateTransponderThreshold($input: TransponderThresholdInput!) {
    createTransponderThreshold(input: $input) {
      id
    }
  }
`;

//Transponder client
export const ALL_TRANSPONDER_CUSTOMERS_QUERY = gql`
  query GetTransponderClients {
    getTransponderClients {
      client_type
      createdAt
      id
      logo
      org_email
      organization
      shadow_clients
      updatedAt
    }
  }
`;

// single transponder client
export const GET_UNIQUE_TRANSPONDER_CUSTOMER_QUERY = gql`
  query GetTransponderClient($getTransponderClientId: ID!) {
    getTransponderClient(id: $getTransponderClientId) {
      client_type
      id
      logo
      org_email
      organization
      shadow_clients
    }
  }
`;

// get Subclients
export const GET_SUBCLIENTS_QUERY = gql`
  query Client($filter: SubClientFilter) {
    getSubClients(filter: $filter) {
      client {
        id
        org_email
        organization
        shadow_clients
        client_type
      }
      id
      org_email
      organization
    }
  }
`;

// get unique subclients
export const GET_UNIQUE_SUBCLIENT_QUERY = gql`
  query GetSubClients {
    getSubClients {
      client {
        client_type
        id
        logo
        org_email
        organization
        shadow_clients
      }
    }
  }
`;
//Logout
export const LOGOUT_MUTATION = gql`
  mutation Logoutuser {
    logoutuser {
      userId
      token
      tokenExpiration
    }
  }
`;
//UserRoles
//Get UserRoles
export const USER_USERGROUP_QUERY = gql`
  query UserUserGroups {
    userUserGroups {
      id
      user_id {
        email
        first_name
        last_name
      }
      group_id {
        group_id
        group_name
      }
    }
  }
`;

//user groyup

export const USERGROUP_QUERY = gql`
  query UserGroups($limit: Int, $offset: Int) {
    userGroups(limit: $limit, offset: $offset) {
      group_id
      group_name
    }
  }
`;

//Comment
//get comment
export const GET_A_COMMENT = gql`
  query Comments($limit: Int!, $offset: Int!, $filter: commentFilter) {
    comments(limit: $limit, offset: $offset, filter: $filter) {
      comment_text
      user {
        first_name
        last_name
        initials
        id
      }
      createdAt
    }
  }
`;
//create comment
export const CREATE_COMMENT = gql`
  mutation CreateComment($input: CommentInput) {
    createComment(input: $input) {
      comment_text
      user {
        first_name
        last_name
        initials
      }
    }
  }
`;

//Employee
export const ALL_EMPLOYEES_QUERY = gql`
  query Employees($limit: Int, $offset: Int) {
    employees(limit: $limit, offset: $offset) {
      first_name
      last_name
      id
    }
  }
`;
//create location
export const CREATE_LOCATION_MUTATION = gql`
  mutation CreateTransponderClientLocation(
    $input: TransponderClientLocationInput
  ) {
    createTransponderClientLocation(input: $input) {
      id
    }
  }
`;

//location
export const ALL_LOCATIONS_QUERY = gql`
  query GetTransponderClientLocations(
    $filter: TransponderClientLocationFilter
    $offset: Int!
    $limit: Int!
  ) {
    getTransponderClientLocations(
      filter: $filter
      offset: $offset
      limit: $limit
    ) {
      count
      data {
        id
        client {
          id
          organization
        }
        location_name
        color
        createdAt
        updatedAt
      }
    }
  }
`;
//User
export const ALL_USER_QUERY = gql`
  query Employees {
    employees {
      first_name
      last_name
      id
      initials
    }
  }
`;
export const ADD_USER_MUTATION = gql`
  mutation CreateUser($createAcc: createuser) {
    createUser(createAcc: $createAcc) {
      user_id
      first_name
      last_name
    }
  }
`;
export const CURRENT_USER_MUTATION = gql`
  query CurrentUser {
    currentUser {
      user_id
      first_name
      last_name
      username
      email
      phone_number

      role {
        group_id {
          group_name
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const EDIT_USER_MUTATION = gql`
  mutation UpdateFileCategory(
    $updateFileCategoryId: Int!
    $input: FileCategoryInput
  ) {
    updateFileCategory(id: $updateFileCategoryId, input: $input) {
      editable
      file_category_id
      file_category_name
    }
  }
`;
export const LOGIN_USER_MUTATION = gql`
  mutation LoginEmployee($username: String!, $password: String!) {
    loginEmployee(username: $username, password: $password) {
      authData {
        token
      }
      employee {
        id
        first_name
        last_name
        phone_number
        title
        address
        initials
      }
      user {
        role {
          id
          group_id {
            group_id
            group_name
          }
        }
      }
    }
  }
`;
export const DELETE_USER_MUTATION = gql`
  mutation DeleteFileCategory($deleteFileCategoryId: Int!) {
    deleteFileCategory(id: $deleteFileCategoryId)
  }
`;

export const UPDATE_USER = gql`
  mutation Updateuser($updateuserId: Int!, $input: UserInput) {
    updateuser(id: $updateuserId, input: $input) {
      user_id
      first_name
      last_name
      username
      email
      date_of_birth
      gender
      location
      profile_picture
      phone_number
    }
  }
`;

export const REQUEST_RESETPASSWORD_MUTATION = gql`
  mutation RequestResetPassword($email: String!) {
    requestResetPassword(email: $email) {
      userId
      username
      email
    }
  }
`;

export const SUB_DEPT_QUERY = gql`
  query SubDepartments(
    $offset: Int
    $limit: Int
    $filter: subDepartmentFilter
  ) {
    subDepartments(offset: $offset, limit: $limit, filter: $filter) {
      id
      name
      department {
        dept_name
        id
      }
      email
      phone
      address
      createdAt
      updatedAt
    }
  }
`;

//PREORDER
export const PREORDER_QUERY = gql`
  query GetTransponderPreOrders(
    $offset: Int!
    $limit: Int!
    $filter: PreOrderFilter
  ) {
    getTransponderPreOrders(offset: $offset, limit: $limit, filter: $filter) {
      count
      data {
        updatedAt
        quantity
        last_sequence
        id
        first_sequence
        date_received
        createdAt
        client {
          organization
          logo
          org_email
        }
        batch_no
        agency {
          agency_name
          id
        }
        account {
          id
          account_name
          account_no
        }
      }
    }
  }
`;
//FULFILMENTS
export const FULFILMENTS_QUERY = gql`
  query GetFulfillments(
    $offset: Int!
    $limit: Int!
    $filter: FulfillmentFilter
  ) {
    getFulfillments(offset: $offset, limit: $limit, filter: $filter) {
      count
      data {
        id
        client {
          organization
        }
        sub_client {
          organization
          org_email
          id
        }
        agency {
          agency_name
          id
        }
        processor {
          id
          first_name
          last_name
        }
        shipment {
          shipping_address
        }
        entity {
          entity
          id
        }
        quantity
        license_plate
        vin_no
        unit_no
        cost_center
        createdAt
      }
    }
  }
`;
export const ASSIGN_FULFILMENT_MUTATION = gql`
  mutation AssignMyselfTransponderFulfillment($fulfillment: ID!) {
    assignMyselfTransponderFulfillment(fulfillment: $fulfillment) {
      id
    }
  }
`;
export const CREATE_FULFILMENT_MUTATION = gql`
  mutation CreateTransponderFulfillment($input: FulfillmentInput!) {
    createTransponderFulfillment(input: $input) {
      id
    }
  }
`;
export const GET_ASSIGNED_TRANSPONDERS_QUERY = gql`
query GetAssignedTransponders($offset: Int!, $limit: Int!, $filter: AssignedTransponderFilter, $transponderFilter: TransponderFilter, $searchParams: String) {
  getAssignedTransponders(offset: $offset, limit: $limit, filter: $filter, transponderFilter: $transponderFilter, searchParams: $searchParams) {
    count
    data {
      id
      transponder {
        account {
          account_name
          account_no
          client {
            organization
          }
          agency {
            agency_name
            abbreviation
          }
        }
        id
        transponder_no
        transponder_id
        transponder_status {
          id
          transponder_status
        }
        location {
          location_name
        }
        request_status
        comment
        deactivation_date
        sub_client {
          organization
        }
      }
      shipping {
        courier {
          courier
        }
        shipping_address
        tracking_no
      }
      license_plate
      vin_no
      unit_no
      createdAt
      updatedAt
      fulfillment {
        cost_center
      }
    }
  }
}
`;

export const DELETE_ASSIGNED_TRANSPONDER_MUTATION = gql`
  mutation DeleteAssignedTransponder($deleteAssignedTransponderId: ID!) {
    deleteAssignedTransponder(id: $deleteAssignedTransponderId) {
      id
    }
  }
`;
export const CHANGE_FULFILLMENT_TO_SHIPPED_MUTATION = gql`
  mutation ChangeFulfillmentToShipped($fulfillment: ID!) {
    changeFulfillmentToShipped(fulfillment: $fulfillment) {
      id
    }
  }
`;
export const GET_UNIQUE_FULFILMENT_QUERY = gql`
  query GetFulfillment($getFulfillmentId: ID!) {
    getFulfillment(id: $getFulfillmentId) {
      vin_no
      unit_no
      shipment {
        shipping_address
      }
      entity {
        entity
        id
      }
      client {
        organization
        org_email
      }
      sub_client {
        organization
        org_email
      }
      license_plate
      id
      cost_center
      agency {
        agency_name
        id
      }
      quantity
      request_status
    }
  }
`;
export const UPDATE_FULFILMENT_MUTATION = gql`
  mutation UpdateTransponderFulfillment($input: FulfillmentUpdateInput!, $updateTransponderFulfillmentId: ID!) {
  updateTransponderFulfillment(input: $input, id: $updateTransponderFulfillmentId) {
    id
  }
}
`;
export const AUTOMATIC_TRANSPONDER_ASSIGNMENT_MUTATION = gql`
  mutation AssignedTransponderAutomatically($fulfillment: ID!) {
    assignedTransponderAutomatically(fulfillment: $fulfillment)
  }
`;

export const GET_FULFILMENT_SHIPMENTS_QUERY = gql`
  query GetShipments($offset: Int!, $limit: Int!, $filter: ShipmentFilter) {
    getShipments(offset: $offset, limit: $limit, filter: $filter) {
      data {
        id
        weight
        tracking_no
        shipping_address
        shipped_date
        drop_off
        createdAt
        updatedAt
        assignedTransponder {
          transponder {
            transponder_no
            transponder_id
          }
          id
          license_plate
        }
        fulfillment {
          id
        }
        courier {
          courier
        }
      }
      count
    }
  }
`;
//STATUS
export const Cli_STATUS_QUERY = gql`
  query Status {
    status {
      id
      status
    }
  }
`;

// Transponder Status
export const TRANSPONDER_STATUS_QUERY = gql`
  query GetTransponderStatus($getTransponderStatusId: ID!) {
    getTransponderStatus(id: $getTransponderStatusId) {
      id
      transponder_status
    }
  }
`;
// Transponder couriers
export const ALL_COURIER_QUERY = gql`
  query GetCouriers {
    getCouriers {
      id
      courier
      createdAt
      updatedAt
    }
  }
`;

export const GET_TRANSPONDERS_SSR_QUERY = gql`
  query GetTransponders(
    $offset: Int!
    $limit: Int!
    $filter: TransponderFilter
    $searchParams: String
  ) {
    getTransponders(
      offset: $offset
      limit: $limit
      filter: $filter
      searchParams: $searchParams
    ) {
      count
      data {
        account {
          account_no
          account_name
        }
        comment
        id
        sub_client {
          organization
          id
        }
        transponder_id
        transponder_no
        transponder_status
        request_status
      }
    }
  }
`;
export const CREATE_ACCOUNT_TYPE_MUTATION = gql`
  mutation CreateAccountType($input: AccountTypeInput!) {
    createAccountType(input: $input) {
      id
    }
  }
`;

export const ALL_AGENCY_ACCOUNTS_TYPE_QUERY = gql`
  query GetTransponderAccountTypes($filter: AccountTypeFilter) {
    getTransponderAccountTypes(filter: $filter) {
      id
      createdAt
      agency {
        agency_name
        id
        abbreviation
      }
      account_type
    }
  }
`;
// MarkedShipped mutation
export const MARK_SHIPPED_MUTATION = gql`
  mutation MarkShipped($fulfillment: ID!) {
    markShipped(fulfillment: $fulfillment) {
      id
    }
  }
`;

//assign maually tansponder mutation
export const ASSIGN_MANUALLY_TRANSPONDER_MUTATION = gql`
 mutation AssignTransponderManually($transponderNo: String!, $fulfillment: ID!, $transponderId: String) {
  assignTransponderManually(transponder_no: $transponderNo, fulfillment: $fulfillment, transponder_id: $transponderId) {
    id
  }
}
`;
//  update shipment
export const UPDATE_SHIPMENT_MUTATION = gql`
  mutation UpdateShipment($input: ShipmentInput!, $updateShipmentId: ID!) {
    updateShipment(input: $input, id: $updateShipmentId) {
      id
    }
  }
`;

export const MARK_SHIPPED = gql`
  mutation MarkFulfillmentAsShipped($markFulfillmentAsShippedId: ID!) {
    markFulfillmentAsShipped(id: $markFulfillmentAsShippedId) {
      id
    }
  }
`;
export const MOVE_TRANSPONDERS_TO_OPENROADS = gql`
 mutation MoveTranspondersToOpenRoads($transponders: [ID!]!) {
  moveTranspondersToOpenRoads(transponders: $transponders)
}
`;
